<template>
  <div>
    <div class="row">
      <div class="col-12 mb-3">
        <transition name="slide-fade" appear>
          <a class="btn btn-link btn-border mr-2" @click="openModalAgregar()" v-if="$agregar(['Perfiles'])">
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'plus', }" class="icon btn-icon" /> NUEVO PERFIL
          </a>
        </transition>
      </div>
    </div>
    <div class="row">
      <transition name="slide-fade" appear>
        <div class="col-12">
          <div class="table-responsive site-table2-responsive">
            <vue-good-table
            styleClass="vgt-table condensed site-table2"
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
              trigger: 'enter',
              skipDiacritics: true,
              placeholder: 'Buscar perfil',
            }">
              <div slot="emptystate">
                <div class="empty-image">
                  <img src="@/assets/empty.svg" alt="Empty">
                  <p class="empty-description">Sin datos</p>
                </div>
              </div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'Acciones'">
                  <b-button class="btn-detail mr-1" title="Editar perfil" v-b-tooltip.hover.bottom.noninteractive
                    @click="openModalEditar(props.row)"
                    v-if="$editar(['Perfiles'])"><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'edit', }" class="icon btn-icon" /></b-button>
                  <b-button class="btn-detail" title="Eliminar perfil" v-b-tooltip.hover.bottom.noninteractive
                    @click="openModalEliminar(props.row)"
                    v-if="$eliminar(['Perfiles'])"><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'trash-alt', }" class="icon btn-icon" /></b-button>
                </span>
                <span v-else-if="props.column.field == 'Tipo'">
                  {{ props.row.Tipo == '0' ? 'Usuario' : 'Proveedor' }}
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </transition>
    </div>
    <b-modal
      id="bv-modal-perfil" size="md" centered hide-footer content-class="site-modal"
      :header-text-variant="'light'"
      v-if="$agregar(['Perfiles']) || $editar(['Perfiles'])">
      <template #modal-title>
        {{ action === 'add' ? 'NUEVO' : 'EDITAR' }} PERFIL
      </template>
      <form class="form-site inner" @submit.prevent="submit()">
        <div class="form-row form-row-select">
          <div class="form-holder form-holder-2" :class="{error: validation.hasError('nombre')}">
            <fieldset>
              <legend>Nombre del Perfil</legend>
              <input type="text" class="form-control" placeholder="Nombre de Perfil" v-model="nombre">
              <span class="icon" :title="validation.firstError('nombre')" v-if="validation.hasError('nombre')">
                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
              </span>
            </fieldset>
          </div>
          <div class="form-holder form-holder-2" :class="{error: validation.hasError('descripcion')}">
            <fieldset>
              <legend>Descripción del Perfil</legend>
              <input type="text" class="form-control" placeholder="Descripción de Perfil" v-model="descripcion">
              <span class="icon" :title="validation.firstError('descripcion')" v-if="validation.hasError('descripcion')">
                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
              </span>
            </fieldset>
          </div>
          <div class="form-holder form-holder-2">
            <fieldset :class="validation.hasError('tipo') ? 'select-error' : ''">
              <legend>Tipo</legend>
              <v-select
                class="form-select"
                placeholder="Tipo de perfil"
                label="label"
                :options="[{ label: 'Usuario', value: 0 }, { label: 'Proveedor', value: 1 }]"
                :reduce="(option) => option.value"
                :searchable="false"
                :clearable="false"
                v-model="tipo">
              </v-select>
            </fieldset>
          </div>
        </div>
        <button type="submit" class="btn btn-block btn-action mb-2" block>
          <font-awesome-icon v-if="loading" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>
          <font-awesome-icon v-if="!loading" :icon="{ prefix: 'fa', iconName: 'save', }" class="icon btn-icon" />Guardar
        </button>
      </form>
    </b-modal>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import apiClient from '@/services/axios';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

export default {
  components: {
    VueGoodTable,
  },
  data() {
    return {
      loading: false,
      action: '',
      perfilId: 0,
      nombre: '',
      descripcion: '',
      tipo: '',
      columns: [
        {
          label: '#',
          field: 'PerfilId',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Nombre Perfil',
          field: 'Nombre',
        },
        {
          label: 'Descripcion',
          field: 'Descripcion',
        },
        {
          label: 'Tipo',
          field: 'Tipo',
        },
        {
          label: 'Acciones',
          field: 'Acciones',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          width: '150px',
        },
      ],
      rows: [],
    };
  },
  validators: {
    nombre(value) { return Validator.value(value).required('Campo obligatorio').lengthBetween(5, 100, 'Longitud de 5 a 100 caracteres'); },
    descripcion(value) { return Validator.value(value).lengthBetween(0, 250, 'Longitud de 5 a 250 caracteres'); },
    tipo(value) { return Validator.value(value).between(0, 1, 'Valor incorrecto'); },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      apiClient.get('/perfiles')
        .then((res) => {
          this.rows = res.data;
        })
        .catch(() => {});
    },
    openModalAgregar() {
      this.nombre = '';
      this.descripcion = '';
      this.tipo = 0;
      this.action = 'add';
      this.$bvModal.show('bv-modal-perfil');
    },
    openModalEditar(row) {
      this.perfilId = row.PerfilId;
      this.nombre = row.Nombre;
      this.descripcion = row.Descripcion;
      this.tipo = row.Tipo;
      this.action = 'edit';
      this.$bvModal.show('bv-modal-perfil');
    },
    openModalEliminar(row) {
      this.$bvModal.msgBoxConfirm('Si continua, se eliminará el Perfil seleccionado.', {
        title: 'Eliminar Perfil',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Si Eliminar',
        cancelTitle: 'Cancelar',
        headerClass: 'text-light',
        footerClass: 'bg-light p-2',
        hideHeaderClose: true,
        centered: true,
      })
        .then((confirm) => {
          if (confirm) {
            this.perfilId = row.PerfilId;
            this.action = 'delete';
            this.submit();
          }
        })
        .catch(() => {});
    },
    submit() {
      if (this.action === 'add' || this.action === 'edit') {
        this.$validate().then((success) => {
          if (success) {
            this.loading = true;
            if (this.action === 'add') {
              apiClient.post('/perfiles', {
                nombre: this.nombre,
                descripcion: this.descripcion,
                tipo: this.tipo,
              })
                .then(() => {
                  this.getData();
                  this.$bvModal.hide('bv-modal-perfil');
                  this.loading = false;
                })
                .catch(() => {
                  this.loading = false;
                });
            }
            if (this.action === 'edit') {
              apiClient.put('/perfiles', {
                perfilId: this.perfilId,
                nombre: this.nombre,
                descripcion: this.descripcion,
                tipo: this.tipo,
              })
                .then(() => {
                  this.getData();
                  this.$bvModal.hide('bv-modal-perfil');
                  this.loading = false;
                })
                .catch(() => {
                  this.loading = false;
                });
            }
          }
        });
      }
      if (this.action === 'delete') {
        if (this.action === 'delete') {
          apiClient.delete('/perfiles', {
            data: {
              perfilId: this.perfilId,
            },
          })
            .then(() => {
              this.getData();
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
